
@font-face {
  font-family: "MalgunGothic";
  src: local("MalgunGothic"),
    url(/static/media/malgun.d2e686b9.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "NotoSansCJKkr";
  src: local("NotoSansCJKkr"),
    url(/static/media/NotoSansCJKkr.1805644d.otf) format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "MalgunGothicRegular";
  src: local("MalgunGothicRegular"),
    url(/static/media/MalgunGothicRegular.3a025ae9.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "MalgunGothicBold";
  src: local("MalgunGothicBold"),
    url(/static/media/MalgunGothicBold.ea08883b.ttf) format("truetype");
  font-weight: normal;
}

html {
  font-family: 'MalgunGothic', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'MalgunGothic',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.custom-select-box .ant-select-selection-item {
  line-height: 50px !important;
}

.custom-select-box .ant-select-selection-placeholder {
  line-height: 50px !important;
}

.custom-select-box .ant-select-selector {
  height: 100% !important;
  border-radius: 0 !important;
  border-width: 1 !important;
  border-color: #ededed !important;
}

.custom-select-box .ant-select-focused .ant-select-selector, .ant-select-selector:focus, .ant-select-selector:active, .ant-select-open .ant-select-selector {
  border-color: #ededed !important;
  box-shadow: none !important;
}

#outer-video-active {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: solid 2px #1273e2;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: black;
  padding-top: 56.25%
}

#outer-video-deactive {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: solid 1px #ededed;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: black;
  padding-top: 56.25%
}

#outer-play-button {
  border-radius: 8px;
  border: solid 1px #ededed;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 44px;
}

.video {
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.custom-modal .ant-modal-content {
  border-radius: 16px;
  overflow: hidden;
}

.custom-modal .ant-modal-body {
  padding: 0;
}

.image-antd>img {
  border-radius: 6px;
}

.render-content {
  font-size: 14px;
}

.render-content p {
  font-family: 'Noto Sans KR', sans-serif !important;
  font-size: 14px;
}


